import { Drawer, IconButton } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import MenuIcon from "@mui/icons-material/Menu"
import Redirect from "components/Redirect/Redirect"
import Gallery from "components/Login/Gallery"
import LoginForm from "components/Login/LoginForm"
import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Translate } from "react-redux-i18n"
import { getUser } from "store/modules/common/auth"
import Notify from "components/Notify/Notify"
import { isWhiteLabelLoginDomain } from "shared"

const useStyles = makeStyles({
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0
    },
    html: {
      fontSize: 13
    }
  },
  root: {
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "rgb(248, 248, 248)",
    color: "#313137"
  },
  header: {
    padding: "12px 30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.07)",
    top: 0,
    width: "100%",
    position: "fixed",
    backgroundColor: "#fff",
    zIndex: 100
  },
  menu: {
    display: "flex",
    alignItems: "baseline",
    listStyle: "none",
    marginBottom: 0,
    "& li a": {
      fontSize: 16,
      textTransform: "uppercase",
      display: "inline-block",
      color: "inherit",
      padding: "13px 18px",
      textDecoration: "none",
      borderBottom: "2px solid",
      borderBottomColor: "transparent"
    },
    "& li a:active": {
      borderBottomColor: "rgba(34,36,38,.15)",
      transition: "color .1s ease"
    }
  },
  jobAdButton: {
    backgroundColor: "#f2711c",
    borderRadius: 4,
    border: "none",
    outline: "none",
    cursor: "pointer",
    textTransform: "uppercase",
    padding: "12px 24px 10px 24px",
    color: "#fff",
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
    fontWeight: 700,
    marginLeft: 18,
    marginRight: 18,
    "&:hover": {
      backgroundColor: "#f26202"
    },
    "&:active": {
      backgroundColor: "#cf590c"
    }
  },
  logo: {
    width: 280,
    padding: "14px 18px"
  },
  container: {
    width: "min(100% - 4em, 1127px)",
    margin: "0 auto"
  },
  stats: {
    padding: "80px 0",
    marginTop: 80,
    backgroundColor: "#fff",
    boxShadow: "0 0 12px 0 rgba(213, 213, 213, 0.50)"
  },
  statsContent: {
    height: 516,
    display: "grid",
    gridTemplateColumns: "2fr 3fr",
    gap: "14px",
    alignItems: "center"
  },
  galleryText: {},
  gallery: {
    height: "100%"
  },
  galleryHeader: {
    fontFamily: "'Roboto Slab', serif",
    lineHeight: 1.6,
    fontSize: 36,
    fontWeight: 700
  },
  getStarted: {
    marginTop: 30,
    backgroundColor: "#f2711c",
    cursor: "pointer",
    border: "none",
    outline: "none",
    color: "#fff",
    padding: "11px 21px",
    fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica,sans-serif",
    fontWeight: 700,
    borderRadius: 4,
    fontSize: 14,
    display: "inline-block",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#f26202"
    },
    "&:active": {
      backgroundColor: "#cf590c"
    },
    "&:visited, &:focus, &:hover, &:active": {
      color: "#fff",
      textDecoration: "none"
    }
  },
  features: {
    padding: "60px 0"
  },
  featureHeader: {
    fontFamily: "'Roboto Slab', serif",
    fontSize: 36,
    textAlign: "center"
  },
  featureList: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "28px",
    marginTop: 54
  },
  feature: {
    padding: 24,
    backgroundColor: "#fff",
    boxShadow: "0 0 12px 0 rgba(213, 213, 213, 0.50)"
  },
  featureContentHeader: {
    fontSize: 24,
    lineHeight: "38.4px"
  },
  featureContentText: {
    marginTop: 14,
    lineHeight: "26.5px"
  },
  more: {
    color: "#fe6501",
    display: "inline-block",
    marginTop: 6,
    marginBottom: 6,
    textDecoration: "none",
    "&:hover": {
      color: "#1e70bf"
    }
  },
  footerLinks: {
    display: "grid",
    gap: "28px",
    gridTemplateColumns: "repeat(3, 1fr)",
    marginTop: 110,
    marginBottom: 40
  },
  footerLinksHeader: {
    fontSize: 25,
    lineHeight: "30.2px",
    letterSpacing: "2.52px",
    marginBottom: 32
  },
  linksList: {
    listStyle: "none",
    "& li": {
      marginBottom: 22
    },
    "& li a": {
      color: "inherit",
      textDecoration: "none"
    }
  },
  footer: {
    paddingBottom: 95
  },
  phone: {
    padding: 30,
    backgroundColor: "#ff6600",
    position: "absolute",
    right: 0,
    color: "#fff",
    fontSize: 22,
    fontWeight: 700,
    lineHeight: "35.2px",
    "& a": {
      textTransform: "uppercase",
      textDecoration: "none",
      color: "inherit"
    }
  },
  menuIcon: {
    display: "none",
    "& svg": {
      fontSize: 28
    }
  },
  mobileMenu: {
    "& li": {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      "& a": {
        padding: "15px 14px",
        display: "block",
        fontSize: 16,
        color: "rgba(0,0,0,0.95)",
        textDecoration: "none",
        "&:active, &:focus": {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },
      "& button": {
        backgroundColor: "#f2711c",
        padding: "15px 14px",
        border: "none",
        outline: "none",
        cursor: "pointer",
        textTransform: "uppercase",
        color: "#fff",
        fontWeight: 700,
        "&:hover": {
          backgroundColor: "#f26202"
        },
        "&:active": {
          backgroundColor: "#cf590c"
        }
      }
    }
  },
  "@media only screen and (max-width: 885px)": {
    container: {
      width: "auto",
      marginLeft: "24px",
      marginRight: "24px"
    },
    galleryHeader: {
      fontSize: 24
    },
    gallery: {
      "& img": {
        display: "block",
        margin: "0 auto",
        width: "auto",
        height: "100%"
      }
    },
    featureList: {
      gridTemplateColumns: "1fr"
    },
    featureHeader: {
      fontSize: "2.1rem"
    },
    statsContent: {
      height: "auto",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 190px",
      gap: "40px"
    },
    menu: {
      display: "none"
    },
    footerLinks: {
      marginTop: 50,
      gridTemplateColumns: "1fr"
    },
    phone: {
      fontSize: 16,
      "& a": {
        display: "inline-block"
      }
    },
    menuIcon: {
      display: "block"
    },
    header: {
      paddingLeft: 24,
      paddingRight: 24
    },
    logo: {
      paddingLeft: 0
    }
  }
})

const Login = () => {
  const classes = useStyles()
  const [ready, setReady] = useState(false)
  const [loginFormOpen, setLoginFormOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const user = useSelector(getUser)

  const openLoginForm = useCallback(() => {
    setLoginFormOpen(true)
  }, [])

  const closeLoginForm = useCallback(() => {
    setLoginFormOpen(false)
  }, [])

  const openMenu = useCallback(() => {
    setMenuOpen(true)
  }, [])

  const closeMenu = useCallback(() => {
    setMenuOpen(false)
  }, [])

  const closeMenuAndOpenForm = useCallback(() => {
    closeMenu()
    openLoginForm()
  }, [closeMenu, openLoginForm])

  useEffect(() => {
    document.documentElement.classList.add("landing")
    setReady(true)
    return () => {
      document.documentElement.classList.remove("landing")
      setReady(false)
    }
  }, [])

  if (isWhiteLabelLoginDomain()) {
    return <Redirect to="/r/login" />
  }

  if (!ready) {
    return null
  }

  if (user) {
    return <Redirect to="/react" />
  }

  return (
    <div id="body" className={classes.root}>
      <LoginForm open={loginFormOpen} onClose={closeLoginForm} />

      <header className={classes.header}>
        <a href="https://jobadpartner.de">
          <img
            className={classes.logo}
            alt="Logo"
            src="/react/assets/img/logo.jpg"
          />
        </a>

        <nav>
          <ul className={classes.menu}>
            <li>
              <a href="https://jobadpartner.de">
                <Translate value="landing.nav-menu.home" />
              </a>
            </li>
            <li>
              <a href="https://jobadpartner.de/ueber-uns">
                <Translate value="landing.nav-menu.about-us" />
              </a>
            </li>
            <li>
              <button className={classes.jobAdButton} onClick={openLoginForm}>
                <Translate value="landing.nav-menu.login" />
              </button>
            </li>
            <li>
              <a href="https://jobadpartner.de/kontakt">
                <Translate value="landing.nav-menu.contact" />
              </a>
            </li>
          </ul>
        </nav>
        <IconButton
          className={classes.menuIcon}
          onClick={openMenu}
          title="Menu"
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </header>

      <section className={classes.stats}>
        <div className={classes.container}>
          <div className={classes.statsContent}>
            <div className={classes.galleryText}>
              <h2 className={classes.galleryHeader}>
                <Translate value="landing.gallery.header" />
              </h2>
              <button className={classes.getStarted} onClick={openLoginForm}>
                <Translate value="landing.gallery.get-started" />
              </button>
            </div>

            <div className={classes.gallery}>
              <Gallery />
            </div>
          </div>
        </div>
      </section>

      <section className={classes.features}>
        <div className={classes.container}>
          <h2 className={classes.featureHeader}>
            <Translate value="landing.features.header" />
          </h2>

          <div className={classes.featureList}>
            <div className={classes.feature}>
              <h3 className={classes.featureContentHeader}>
                <Translate value="landing.features.hr-solution.title" />
              </h3>
              <div className={classes.featureContentText}>
                <Translate value="landing.features.hr-solution.text" />
              </div>
              <a
                href="https://jobadpartner.de/mein-job-ad-partner-shop/"
                className={classes.more}
              >
                <Translate value="landing.features.learn-more" />
              </a>
            </div>
            <div className={classes.feature}>
              <h3 className={classes.featureContentHeader}>
                <Translate value="landing.features.bms-integration.title" />
              </h3>
              <div className={classes.featureContentText}>
                <Translate value="landing.features.bms-integration.text" />
              </div>
              <a
                href="https://jobadpartner.de/bewerbermanagementsystem/"
                className={classes.more}
              >
                <Translate value="landing.features.learn-more" />
              </a>
            </div>
            <div className={classes.feature}>
              <h3 className={classes.featureContentHeader}>
                <Translate value="landing.features.performance-marketing.title" />
              </h3>
              <div className={classes.featureContentText}>
                <Translate value="landing.features.performance-marketing.text" />
              </div>
              <a
                href="https://jobadpartner.de/performance-personalmarketing/"
                className={classes.more}
              >
                <Translate value="landing.features.learn-more" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer className={classes.footer}>
        <div className={classes.container}>
          <div className={classes.footerLinks}>
            <div>
              <div className={classes.footerLinksHeader}>
                <Translate value="landing.links.companies.title" />
              </div>
              <ul className={classes.linksList}>
                <li>
                  <a href="https://jobadpartner.de/karriere">
                    <Translate value="landing.links.companies.career" />
                  </a>
                </li>
                <li>
                  <a href="https://jobadpartner.de/ueber-uns">
                    <Translate value="landing.links.companies.about-us" />
                  </a>
                </li>
                <li>
                  <a href="https://jobadpartner.de/kontakt">
                    <Translate value="landing.links.companies.contact" />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <div className={classes.footerLinksHeader}>
                <Translate value="landing.links.legal-information.title" />
              </div>
              <ul className={classes.linksList}>
                <li>
                  <a href="https://jobadpartner.de/impressum">
                    <Translate value="landing.links.legal-information.imprint" />
                  </a>
                </li>
                <li>
                  <a href="https://jobadpartner.de/agb">
                    <Translate value="landing.links.legal-information.conditions" />
                  </a>
                </li>
                <li>
                  <a href="https://jobadpartner.de/disclaimer">
                    <Translate value="landing.links.legal-information.disclaimer" />
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <div className={classes.footerLinksHeader}>
                <Translate value="landing.links.follow-us.title" />
              </div>
              <ul className={classes.linksList}>
                <li>
                  <a href="https://www.linkedin.com/company/job-ad-partner">
                    <Translate value="landing.links.follow-us.linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.xing.com/company/jobadpartner">
                    <Translate value="landing.links.follow-us.xing" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/jobadpartner/">
                    <Translate value="landing.links.follow-us.facebook" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className={classes.phone}>
          <Translate value="landing.phone-consultation" />:{" "}
          <a href="tel:03069004590">030 – 69 00 45 90</a>
        </div>
      </footer>
      <Drawer anchor="right" open={menuOpen} onClose={closeMenu}>
        <ul className={classes.mobileMenu}>
          <li>
            <a href="https://jobadpartner.de">
              <Translate value="landing.nav-menu.home" />
            </a>
          </li>
          <li>
            <a href="https://jobadpartner.de/ueber-uns">
              <Translate value="landing.nav-menu.about-us" />
            </a>
          </li>
          <li>
            <button onClick={closeMenuAndOpenForm}>
              <Translate value="landing.nav-menu.login" />
            </button>
          </li>
          <li>
            <a href="https://jobadpartner.de/kontakt">
              <Translate value="landing.nav-menu.contact" />
            </a>
          </li>
        </ul>
      </Drawer>
      <Notify />
    </div>
  )
}

export default Login
