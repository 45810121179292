import React from "react"
import { Translate } from "react-redux-i18n"
import { Link } from "react-router-dom"

export const Login = () => {
  return (
    <Link to="/react/login">
      <Translate value="passwords.login" />
    </Link>
  )
}

export const PasswordReset = () => {
  return (
    <Link to="/react/password-reset">
      <Translate value="passwords.password-reset.title" />
    </Link>
  )
}

export const ResendConfirmation = () => {
  return (
    <Link to="/react/resend-confirmation-instructions">
      <Translate value="passwords.no-confirmation" />
    </Link>
  )
}
