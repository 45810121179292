import { Box } from "@mui/material"
import React, { memo } from "react"

export const ColorPreview = memo(
  /**
   * @param {object} props
   * @param {string} props.value
   * @param {boolean} [props.fullWidth]
   */
  ({ value, fullWidth }) => {
    return (
      <Box
        minHeight={54}
        width={fullWidth ? "100%" : "auto"}
        maxWidth={fullWidth ? "none" : 132}
        borderRadius="12px"
        bgcolor={value}
      />
    )
  }
)
