import { Dialog, lighten } from "@mui/material"
import DialogContent from "@mui/material/DialogContent/DialogContent"
import makeStyles from "@mui/styles/makeStyles"
import CloseIcon from "@mui/icons-material/Close"
import { Field, Form, Formik } from "formik"
import React from "react"
import { useDispatch } from "react-redux"
import { setLocale, Translate } from "react-redux-i18n"
import { useHistory } from "react-router-dom"
import { login } from "store/modules/common/auth"
import * as Links from "components/Passwords/Links"
import { error as errorNotify } from "store/modules/common/notify"
import useQueryParams from "hooks/react-router/useQueryParams"

const useStyles = makeStyles({
  root: {
    padding: "0px 40px 40px 40px !important",
    fontFamily: "Roboto, sans-serif"
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    gap: "68px",
    width: "100%",
    maxWidth: 928
  },

  loginForm: {
    flex: "6 0 240px",
    padding: 40,
    backgroundColor: "rgb(255, 243, 236)",
    marginRight: -40,
    "& h2": {
      fontSize: 28,
      fontWeight: 700
    },
    "& fieldset": {
      marginTop: 16,
      border: "none",
      "& > div": {
        marginBottom: 16
      }
    },
    "& label": {
      display: "block",
      fontSize: 15,
      fontWeight: 700,
      "& .asterisk": {
        color: "rgb(255, 0, 0)"
      }
    },
    "& input": {
      marginTop: 1,
      backgroundColor: "transparent !important",
      border: "none",
      padding: "11px 16px 5px 16px",
      borderBottom: "1px solid black",
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      borderRadius: 4,
      outline: "none",
      fontSize: 16,
      width: "100%",
      "&:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 30px rgb(255, 243, 236) inset !important"
      }
    },
    "& button": {
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      fontSize: 14,
      fontWeight: 700,
      color: "white",
      backgroundColor: "rgb(27, 28, 29)",
      padding: "11px 21px",
      borderRadius: 4,
      border: "none",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#27292a"
      },
      "&:active": {
        backgroundColor: "#343637"
      }
    }
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    flexWrap: "wrap",
    gap: "24px",
    "& a": {
      "&, &:visited": {
        color: "black",
        textDecoration: "none"
      },
      "&:hover": {
        color: lighten("#000", 0.3)
      },
      "&:focused": {
        color: lighten("#000", 0.5)
      }
    }
  },
  info: {
    flex: "10 1 240px",
    paddingTop: 40,
    "& h1": {
      fontSize: 24,
      lineHeight: 1.6,
      fontWeight: 400
    }
  },
  features: {
    marginTop: 10,
    paddingLeft: 40,
    lineHeight: "25.6px",
    fontSize: 16
  },
  phone: {
    marginTop: 10,
    border: "1px solid orange",
    padding: "20px 50px",
    fontSize: 16,
    "& div": {
      marginTop: 8,
      fontSize: 26
    },
    "& a": {
      color: "#ff6602",
      textDecoration: "none",
      "&:hover, &:visited, &:focus, &:active": {
        color: "#ff6602",
        textDecoration: "none"
      }
    }
  },
  closeBtn: {
    position: "absolute",
    top: 10,
    right: 10,
    background: "none",
    border: "none",
    outline: "none",
    cursor: "pointer",
    height: 23,
    width: 23,
    "&:hover": {
      color: "#ff6602"
    },
    "& svg": {
      fontSize: 23
    }
  },
  "@media only screen and (max-width: 885px)": {
    loginForm: {
      marginLeft: -40
    }
  }
})

const LoginForm = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQueryParams()

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(login({ data: values })).then((response) => {
      setSubmitting(false)
      if (response instanceof Error) {
        const error = JSON.parse(response.message)
        dispatch(errorNotify(error.body.error))
        return
      }
      if (response.body.user?.locale) {
        dispatch(setLocale(response.body.user.locale))
      }
      const location = query.get("location")

      if (location) {
        window.location = location
      } else {
        history.push("/")
      }
    })
  }

  return (
    <Dialog open={open} maxWidth="xl" TransitionProps={{ timeout: 600 }}>
      <DialogContent className={classes.root} id="login-form-dialog">
        <button className={classes.closeBtn} onClick={onClose}>
          <CloseIcon />
        </button>
        <div className={classes.content}>
          <div className={classes.info}>
            <h1>
              <Translate value="landing.login-form.features.title" />
            </h1>
            <ul className={classes.features}>
              <li>
                <Translate value="landing.login-form.features.manage-circuits" />
              </li>
              <li>
                <Translate value="landing.login-form.features.manage-reports" />
              </li>
              <li>
                <Translate value="landing.login-form.features.book-ads" />
              </li>
            </ul>
            <div className={classes.phone}>
              <Translate value="landing.phone-consultation" />{" "}
              <div>
                <a href="tel:030690045944">030 - 69 00 45 9 - 44</a>
              </div>
            </div>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
            render={({ isSubmitting }) => {
              return (
                <Form className={classes.loginForm}>
                  <h2>
                    <Translate value="landing.login-form.title" />
                  </h2>
                  <fieldset>
                    <div>
                      <label htmlFor="email">
                        <Translate value="landing.login-form.email" />{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <Field
                        id="email"
                        type="text"
                        name="email"
                        placeholder="Please enter your email"
                      />
                    </div>
                    <div>
                      <label htmlFor="password">
                        <Translate value="landing.login-form.password" />{" "}
                        <span className="asterisk">*</span>
                      </label>
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Please enter your password"
                      />
                    </div>
                    <div className={classes.actions}>
                      <button disabled={isSubmitting} type="submit">
                        <Translate value="landing.login-form.submit" />
                      </button>
                      <div>
                        <Links.PasswordReset />
                      </div>
                    </div>
                  </fieldset>
                </Form>
              )
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LoginForm
