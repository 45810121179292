import React, { useCallback, useEffect, useState } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import makeStyles from "@mui/styles/makeStyles"
import GallerySlide from "components/Login/GallerySlide"

const useRecursiveTimeout = (callback, delay) => {
  const [running, setRunning] = useState(false)
  const play = useCallback(() => setRunning(true), [setRunning])
  const suspend = useCallback(() => {
    setRunning(false)
    setTimeout(play, delay * 5)
  }, [delay, play])

  useEffect(() => {
    if (!running) return undefined
    let id

    const tick = () => {
      if (!running) {
        clearTimeout(id)
        return
      }
      callback()
      requestAnimationFrame(() => (id = setTimeout(tick, delay)))
    }
    requestAnimationFrame(() => (id = setTimeout(tick, delay)))

    return () => {
      if (id) clearTimeout(id)
    }
  }, [running, delay, suspend, callback])

  return { play, suspend }
}

const useStyles = makeStyles({
  viewport: {
    overflow: "hidden",
    width: "100%",
    height: "100%"
  },
  container: {
    display: "flex",
    height: "100%"
  }
})

const Gallery = () => {
  const classes = useStyles()
  const [emblaRef, embla] = useEmblaCarousel({ loop: true })

  const autoplay = useCallback(() => {
    embla?.scrollNext()
  }, [embla])

  const { play, suspend } = useRecursiveTimeout(autoplay, 2000)

  useEffect(() => {
    embla?.on("pointerDown", suspend)
  }, [embla, suspend])

  useEffect(() => {
    play()
  }, [play])

  return (
    <div className={classes.viewport} ref={emblaRef}>
      <div className={classes.container}>
        <GallerySlide src="/react/assets/img/analytic.jpg" />
        <GallerySlide src="/react/assets/img/graphic.jpg" />
        <GallerySlide src="/react/assets/img/performance.jpg" />
      </div>
    </div>
  )
}

export default Gallery
