import React, { memo } from "react"
import { Tooltip, Typography } from "@mui/material"

export const TooltipCell = memo(({ value, sx = {} }) => {
  if (!value) {
    return "—"
  }

  return (
    // By default, `placement="bottom"` is used. However, if you hover over
    // the <TooltipCell /> in the last row and there's no space below the
    // table for the tooltip to appear (e.g., in the Call Tracking view),
    // the page becomes extremely slow, and a second scrollbar appears.
    <Tooltip placement="top" title={value}>
      <Typography
        sx={{
          fontSize: "0.75rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          ...sx
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  )
})
