import React, { useCallback, useEffect, useState, useRef } from "react"
import { Fab } from "@mui/material"
import Box from "@mui/system/Box"
import CircularProgress, {
  circularProgressClasses
} from "@mui/material/CircularProgress"
import { getIsDocumentHidden } from "shared/lib/visibility"

const getCircularSize = (size) => {
  switch (size) {
    case "small":
      return 32
    case "medium":
      return 48
    default:
      return 56
  }
}

/**
 * @param {import('@mui/material').FabProps & {component?: string; isActive:boolean; interval:number, size:string}} props
 */
export const TimerButton = ({
  isActive = true,
  interval = 600,
  onClick,
  isLoading: externalLoading,
  ...rest
}) => {
  const active = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const intervalInMilliseconds = interval * 1000
  const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState(
    new Date().getTime()
  )
  const [millisecondsFromLastUpdate, setMillisecondsFromLastUpdate] =
    useState(0)
  const progress = (millisecondsFromLastUpdate / intervalInMilliseconds) * 100

  const handleReset = useCallback(
    (e) => {
      const hidden = getIsDocumentHidden()
      if (!hidden) {
        setLastRefreshTimestamp(new Date().getTime())
        setIsLoading(true)
        onClick(e).then(() => setIsLoading(false))
        setMillisecondsFromLastUpdate(0)
      }
    },
    [onClick]
  )

  const handleProgress = useCallback(() => {
    if (active.current) {
      const newValue = new Date().getTime() - lastRefreshTimestamp
      if (newValue >= intervalInMilliseconds) {
        handleReset()
      } else {
        setMillisecondsFromLastUpdate(newValue)
      }
      setTimeout(progressCallback.current, 100)
    }
  }, [handleReset, intervalInMilliseconds, lastRefreshTimestamp])

  const progressCallback = useRef(handleProgress)
  useEffect(() => {
    progressCallback.current = handleProgress
  }, [handleProgress])

  useEffect(() => {
    if (isActive) {
      active.current = true
      setTimeout(progressCallback.current, 100)
    }

    return () => {
      active.current = false
    }
  }, [isActive])

  useEffect(() => {
    if (externalLoading === false) {
      setLastRefreshTimestamp(new Date().getTime())
      setMillisecondsFromLastUpdate(0)
    }
  }, [externalLoading])

  return (
    <Box sx={{ position: "relative", display: "inline-block", ml: 2 }}>
      <Fab
        {...rest}
        color="default"
        variant="circular"
        data-testid="timer-button"
        disabled={isLoading || rest.disabled}
        title={rest.title}
        sx={{
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent"
          }
        }}
        onClick={handleReset}
      />
      <Box sx={{ position: "absolute", top: 0, left: 0, zIndex: 10 }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: "grey.200"
          }}
          size={getCircularSize(rest.size)}
          thickness={5}
          value={100}
        />
        <CircularProgress
          variant={
            isLoading || externalLoading ? "indeterminate" : "determinate"
          }
          sx={{
            color: "primary.main",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round"
            }
          }}
          size={getCircularSize(rest.size)}
          thickness={5}
          value={progress}
        />
      </Box>
    </Box>
  )
}
