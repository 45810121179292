import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import React from "react"

import { getFullHeightSx } from "./sx"

const MainCard = ({
  border = true,
  boxShadow = false,
  children,
  content = true,
  contentClass = "",
  contentSX = {},
  headerSX = {},
  withDivider = true,
  darkTitle = null,
  secondary = null,
  shadow = null,
  fullHeight = false,
  fullHeightOffset = 185,
  dividerPadding = false,
  sx = {},
  htmlTitle = null,
  title = null,
  ...others
}) => {
  const theme = useTheme()

  return (
    <Card
      {...others}
      title={htmlTitle}
      sx={{
        border: border ? "1px solid" : "none",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.primary[200] + 75,
        ":hover": {
          boxShadow: boxShadow
            ? shadow ||
              (theme.palette.mode === "dark"
                ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
                : "0 2px 14px 0 rgb(32 40 45 / 8%)")
            : "inherit"
        },
        "& .MuiCardHeader-action": {
          marginRight: "unset"
        },
        ...(fullHeight ? getFullHeightSx(fullHeightOffset) : {}),
        ...sx
      }}
    >
      {/* card header and action */}
      {!darkTitle && title && (
        <CardHeader
          sx={headerSX}
          title={
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
          }
          action={secondary}
        />
      )}
      {darkTitle && title && (
        <CardHeader
          sx={headerSX}
          title={
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
          }
          action={secondary}
        />
      )}

      {/* content & header divider */}
      {title && withDivider && <Divider sx={{ mx: dividerPadding ? 2 : 0 }} />}

      {/* card content */}
      {content && (
        <CardContent sx={contentSX} className={contentClass}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  )
}

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]),
  shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ])
}

export default MainCard
