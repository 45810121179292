import { useHistory } from "react-router-dom"
import useEffectOnce from "hooks/useEffectOnce"

const Redirect = ({ to }) => {
  const history = useHistory()

  useEffectOnce(() => {
    history.push(to)
  })

  return null
}

export default Redirect
