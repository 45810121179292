import PropTypes from "prop-types"
import React, { memo } from "react"
import { Link } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { Box, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material"

export const TabPanel = ({ children, sx = {}, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={sx}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export const Tab = ({ label, icon = null, ...rest }) => {
  return <MuiTab component={Link} to="#" label={label} icon={icon} {...rest} />
}
export const Tabs = ({ value, onChange, textColor = "primary", ...rest }) => {
  const theme = useTheme()
  return (
    <MuiTabs
      value={value}
      variant="scrollable"
      onChange={onChange}
      sx={{
        mb: 2,
        "& a": {
          minWidth: 10,
          py: 1.5,
          px: 1,
          mr: 5,
          color: theme.palette.grey[600],
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center"
        },
        "& a.Mui-selected": {
          color: theme.palette[textColor].main
        },
        "& a > svg": {
          mb: "0px !important",
          mr: 1.1
        }
      }}
      {...rest}
    />
  )
}

export const TabList = memo(
  /**
   * @param {import("@mui/material").TabsProps & { labels: React.ReactNode[], validationStatus: boolean[]}} props
   * @returns
   */
  ({ labels, value, onChange, validationStatus, sx }) => {
    const theme = useTheme()

    return (
      <Tabs value={value} onChange={onChange} sx={sx}>
        {labels.map((label, i) => {
          return (
            <Tab
              key={i}
              label={label}
              sx={
                validationStatus && validationStatus[i]
                  ? {
                      color: `${theme.palette.error.main} !important`,
                      borderBottom: `2px solid ${theme.palette.error.main}`
                    }
                  : null
              }
            />
          )
        })}
      </Tabs>
    )
  }
)
