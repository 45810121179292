import { Chip, IconButton, Typography } from "@mui/material"
import LockIcon from "@mui/icons-material/Lock"
import RemoteAutocomplete from "components/TouchDesign/Components/RemoteAutocomplete"
import React from "react"
import { formatPrice } from "utils/parsers/prices"

const getLabel = ({ budget, sales_product: product }) =>
  `${product.supplier_name} | ${product.name} (${
    product.runtime_in_days
  }) | ${formatPrice(product.rrp)} ${budget ? "[Budget]" : ""}`

const SalesProductsAutocomplete = ({ tagProps, value, ...rest }) => {
  return (
    <RemoteAutocomplete
      {...rest}
      value={value}
      variant="ransack"
      searchProp="q.formatted_supplier_name_name_runtime_cont"
      getOptionLabel={getLabel}
      onChange={(newValue, reason) => {
        if (reason === "selectOption") {
          const addedOption = newValue[newValue.length - 1]
          const removedOption = newValue.find(
            (product) =>
              product.sales_product_id === addedOption.sales_product_id
          )
          const nextValue = newValue.filter(
            (product) =>
              product.sales_product_id !== addedOption.sales_product_id
          )
          nextValue.push({ ...addedOption, id: removedOption?.id })
          rest.onChange(nextValue, reason)
        } else rest.onChange(newValue, reason)
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          if (option._destroy) return null
          return (
            <Chip
              key={option.id}
              label={getLabel(option)}
              icon={option.sales_product?.unlocked ? null : <LockIcon />}
              {...getTagProps({ index })}
              onDelete={() => {
                const newValue = value.slice()
                if (newValue[index].id) {
                  newValue[index]._destroy = true
                } else newValue.splice(index, 1)
                rest.onChange(newValue, "removeOption")
              }}
            />
          )
        })
      }
      renderOption={(props, option) => (
        <li
          {...props}
          key={
            option?.id ??
            // @ts-ignore
            props.key
          }
        >
          <Typography noWrap>
            {option.sales_product?.unlocked ? null : (
              <IconButton title="Locked" size="large">
                <LockIcon />
              </IconButton>
            )}
            {getLabel(option)}
          </Typography>
        </li>
      )}
      url={"/affiliates/sales_products"}
    />
  )
}

export default SalesProductsAutocomplete
