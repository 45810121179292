import React from "react"
import Editor from "react-simple-code-editor"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
  editor: {
    border: "1px solid black",
    fontSize: 16,
    fontFamily: "monospace",
    marginBottom: 10
  }
})

const CodeEditor = ({ value, onChange, highlight, name, padding }) => {
  const classes = useStyles()

  return (
    <Editor
      name={name}
      padding={padding}
      className={classes.editor}
      value={value}
      onValueChange={onChange}
      highlight={highlight}
    />
  )
}

export default CodeEditor
