/**
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
export const comparePercent = (a, b) => {
  if (!b) {
    return null
  }

  return ((a - b) / b) * 100
}
