import { TIMEOUT } from "../../middleware/clientMiddleware"
import filter from "lodash/filter"
import uniqueId from "lodash/uniqueId"
import { UPDATE_INVOICE_FAIL } from "store/modules/entities/invoices/index"
import { combineReducers } from "redux"
import { LOAD_CREDENTIALS_FAIL } from "store/modules/entities/admin"
import { useSelector } from "react-redux"
import { useMemo } from "react"

const ERROR = "jass/notify/ERROR"
const SUCCESS = "jass/notify/SUCCESS"
const INFO = "jass/notify/INFO"
const REMOVE = "jass/notify/REMOVE"
const CONFIRMATION = "jass/notify/CONFIRMATION"

const appendNotification = (state, type, i18n) => [
  ...state,
  createNotify(type, { i18n, kebab: false })
]

const list = (state = [], action) => {
  switch (action.type) {
    case INFO:
    case SUCCESS:
    case ERROR:
    case CONFIRMATION:
      return [...state, action.notify]
    case REMOVE:
      return filter([...state], (notify) => notify.id !== action.id)
    case TIMEOUT:
      return appendNotification(state, "error", "errors.server.timeout")
    case UPDATE_INVOICE_FAIL:
      return appendNotification(state, "error", "campaigns.end-customers.error")
    case LOAD_CREDENTIALS_FAIL:
      return appendNotification(state, "error", "errors.server.500")
    default:
      return state
  }
}

export default combineReducers({
  list
})

export function error(message) {
  return {
    type: ERROR,
    notify: createNotify("error", message)
  }
}

export function info(message) {
  return {
    type: INFO,
    notify: createNotify("info", message)
  }
}

export const success = (message) => ({
  type: SUCCESS,
  notify: createNotify("success", message)
})

export function confirm(message, options) {
  return {
    type: CONFIRMATION,
    notify: createNotify("confirmation", message, options)
  }
}

export function remove(id) {
  return { type: REMOVE, id }
}

function createNotify(type, message, rest) {
  return {
    id: uniqueId("notify"),
    type,
    message,
    ...rest
  }
}

const SNACKBAR_NOTIFICATION_TYPES = ["success", "info", "error", "warning"]

export const useSnackbarNotifications = () => {
  const notifyList = useSelector((state) => state.notify.list)
  return useMemo(
    () =>
      notifyList.filter((n) => {
        return SNACKBAR_NOTIFICATION_TYPES.find((type) => type === n.type)
      }),
    [notifyList]
  )
}

export const useConfirmationNotification = () => {
  const notifyList = useSelector((state) => state.notify.list)
  return useMemo(
    () =>
      notifyList.find((notification) => notification.type === "confirmation"),
    [notifyList]
  )
}
