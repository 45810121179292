import React, { useState } from "react"

import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { useDropzone } from "react-dropzone"

const CustomDivider = () => {
  return (
    <Stack position="relative" justifyContent="center">
      <Divider variant="fullWidth" />
      <Typography
        variant="h5"
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          color: "#000000",
          backgroundColor: "#ffffff",
          px: 3
        }}
        fontWeight={700}
      >
        OR
      </Typography>
    </Stack>
  )
}

export const URLFilePicker = ({
  name,
  value,
  onChange,
  placeholder,
  getURL,
  onUploadStart,
  onUploadSettled,
  onUploadError,
  onUpload,
  onURLUpload,
  accept,
  onDelete,
  disabled,
  error,
  helperText,
  buttonText
}) => {
  const [url, setURL] = useState(getURL(value) ?? "")

  const handleInputChange = (e) => {
    setURL(e.target.value)
  }

  const handleChange = (value) => {
    onChange(null, value)
    setURL(getURL(value) ?? "")
  }

  const handleBlur = () => {
    if (url && getURL(value) !== url) {
      onUploadStart()
      onURLUpload(url)
        .then(handleChange)
        .catch(onUploadError)
        .finally(onUploadSettled)
    }

    if (!url) {
      onUploadStart()
      if (onDelete && value) {
        onDelete(value)
          .then(() => handleChange(null))
          .catch(onUploadError)
          .finally(onUploadSettled)
      } else {
        handleChange(null)
        onUploadSettled()
      }
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    disabled,
    multiple: false,
    onDrop: (acceptedFiles, _rejectedFiles) => {
      if (!acceptedFiles.length) {
        return Promise.resolve()
      }

      onUploadStart()

      const file = acceptedFiles[0]

      return onUpload(file)
        .then((file) => handleChange(file))
        .catch(onUploadError)
        .finally(onUploadSettled)
    }
  })

  return (
    <Stack divider={<CustomDivider />} spacing={2}>
      <TextField
        disabled={disabled}
        placeholder={placeholder}
        value={url}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      <Stack {...getRootProps()}>
        <input {...getInputProps({ name })} />
        <Button
          disabled={disabled}
          sx={{ textTransform: "none" }}
          variant="contained"
          onClick={open}
        >
          {buttonText}
        </Button>
        {!!error && (
          <FormControl error variant="standard">
            <FormHelperText id="component-error-text">
              {helperText}
            </FormHelperText>
          </FormControl>
        )}
      </Stack>
    </Stack>
  )
}
