import React, { Suspense, useEffect, useState } from "react"
import Login from "routes/login"
import { Provider, useDispatch } from "react-redux"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import { load as loadAuth } from "store/modules/common/auth"
import { loadLocaleFromBackEnd } from "store/modules/common/utils"
import {
  loadTranslations as setTranslations,
  setLocale
} from "react-redux-i18n"
import GlobalSpinner from "components/Spinner/GlobalSpinner"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import IncompleteOfferConfirmationPage from "routes/offers/confirm/[token]"
import { defaultLanguage } from "utils/api"
import JassThemeProvider from "components/App/JassThemeProvider"
import * as WS from "shared/lib/web-sockets"
import { useWebSocketsCleanup } from "shared/hooks"

import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import ReactQuerySeleniumTools from "utils/ReactQuerySeleniumTools"
import { Loadable } from "shared/ui/berry-jass"
import {
  isApplyRecrumediaDomain,
  isOffersRecrumediaDomain,
  isWhiteLabelLoginDomain
} from "shared"
import PrivateRoute from "components/Router/PrivateRoute"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false }
  }
})

const helmetContext = {}

const ApplyRecrumedia = React.lazy(() => import("components/ApplyRecrumedia"))
const NotFound = React.lazy(() => import("routes/not-found"))
const App = React.lazy(() => import("components/App/App"))
const JassBerryApp = React.lazy(() => import("components/App/JassBerryApp"))
const WhiteLogin = Loadable(React.lazy(() => import("routes/white-login")))
const Forgot = Loadable(
  React.lazy(() => import("routes/white-login/forgot-password"))
)
const ChangePassword = Loadable(
  React.lazy(() => import("routes/white-login/change-password"))
)
const OfferAccept = Loadable(React.lazy(() => import("routes/offers/accept")))

const Routes = () => {
  const dispatch = useDispatch()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (isApplyRecrumediaDomain() || isOffersRecrumediaDomain()) {
      setReady(true)
    } else {
      Promise.all([
        dispatch(loadAuth()).then((response) => {
          dispatch(setLocale(response?.user?.locale ?? defaultLanguage()))

          if (response.user?.id) {
            WS.setup(response.user)
          }
        }),
        dispatch(loadLocaleFromBackEnd()).then(({ translations }) =>
          dispatch(setTranslations(translations))
        )
      ]).then(() => setReady(true))
    }
  }, [dispatch])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has("white-label-login")) {
      try {
        localStorage.setItem("white-label-login", "true")
      } catch {
        // eslint-disable-next-line no-console
        console.error("localStorage is not available")
      }
    }
  }, [])

  if (!ready) {
    return <GlobalSpinner />
  }

  return (
    <Suspense fallback={<GlobalSpinner />}>
      {isApplyRecrumediaDomain() && (
        <Switch>
          <Route path="/:vacancyToken">
            <ApplyRecrumedia />
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      )}
      {isOffersRecrumediaDomain() && (
        <Switch>
          <Route path="/confirm/:token">
            <IncompleteOfferConfirmationPage />
          </Route>
          <Route path="*" component={NotFound} status={404} />
        </Switch>
      )}
      {!isApplyRecrumediaDomain() && !isOffersRecrumediaDomain() && (
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Redirect
                to={isWhiteLabelLoginDomain() ? "/r/login" : "/react/login"}
              />
            )}
          />

          <Route path="/react/offers/confirm/:token">
            <IncompleteOfferConfirmationPage />
          </Route>

          <Route path="/react/apply/:vacancyToken">
            <ApplyRecrumedia />
          </Route>

          <Route path="/react/login">
            <JassThemeProvider>
              <Login />
            </JassThemeProvider>
          </Route>
          <Route path="/react">
            <App />
          </Route>

          {isWhiteLabelLoginDomain() && (
            <Route path="/r/login">
              <WhiteLogin />
            </Route>
          )}

          {isWhiteLabelLoginDomain() && (
            <Route path="/r/forgot">
              <Forgot />
            </Route>
          )}
          {isWhiteLabelLoginDomain() && (
            <Route path="/r/change-password">
              <ChangePassword />
            </Route>
          )}

          <Route path="/r/offers/accept/:offerNumber/:token">
            <OfferAccept />
          </Route>

          <PrivateRoute path="/r">
            <JassBerryApp />
          </PrivateRoute>
        </Switch>
      )}
    </Suspense>
  )
}

const Root = ({ store }) => {
  useWebSocketsCleanup()
  const reactQueryDevtoolsActive =
    // @ts-expect-error
    __DEVELOPMENT__ &&
    new URL(document.location.toString()).searchParams.get(
      "react-query-devtools"
    )

  return (
    <Provider store={store} key="provider">
      <QueryClientProvider client={queryClient}>
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </HelmetProvider>
        {reactQueryDevtoolsActive && (
          <ReactQueryDevtools containerElement="div" />
        )}
        <ReactQuerySeleniumTools />
      </QueryClientProvider>
    </Provider>
  )
}

export default Root
