import React, { memo, useMemo } from "react"
import { Translate } from "react-redux-i18n"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Stack, Typography, useTheme } from "@mui/material"

/**
 * @param {object} props
 * @param {number} props.value
 * @param {'medium' | 'small'} [props.size]
 * @param {string} [props.color]
 */
const PercentDelta = ({
  value,
  size = "medium",
  color,
  sx = {},
  showBlank
}) => {
  const theme = useTheme()

  const displayColor = useMemo(() => {
    if (color) {
      return color
    }

    if (value > 0) {
      return theme.palette.success.main
    }

    if (value === 0) {
      return theme.palette.success.grey
    }

    return theme.palette.error.main
  }, [
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.success.grey,
    value,
    color
  ])

  if (!value && !showBlank) {
    return null
  }

  const iconSX = size === "small" ? { height: 12, width: 12 } : {}

  return (
    <Stack
      direction="row"
      color={displayColor}
      alignItems="center"
      pb={size === "small" ? 0 : 0.5}
      sx={{ opacity: value === 0 ? 0.75 : 1, ...sx }}
    >
      {value < 0 ? (
        <ArrowDropDownIcon sx={iconSX} />
      ) : (
        <ArrowDropUpIcon sx={iconSX} />
      )}
      <Typography variant={size === "small" ? "caption" : "h4"} color="inherit">
        {value === 0 ? <Translate value="n_a" /> : `${Math.abs(value)}%`}
      </Typography>
    </Stack>
  )
}

export default memo(PercentDelta)
