import React from "react"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
  slide: {
    position: "relative",
    minWidth: "100%",
    height: "100%"
  },
  slideInner: {
    position: "relative",
    overflow: "hidden",
    height: "100%"
  },
  slideImg: {
    position: "absolute",
    display: "block",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "100%"
  }
})

const GallerySlide = ({ src }) => {
  const classes = useStyles()

  return (
    <div className={classes.slide}>
      <div className={classes.slideInner}>
        <img className={classes.slideImg} src={src} />
      </div>
    </div>
  )
}

export default GallerySlide
