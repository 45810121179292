import React from "react"
import { Button } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import FilterAltIcon from "@mui/icons-material/FilterAlt"

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const ClearAllButton = (props) => {
  return (
    <Button
      startIcon={<CloseIcon />}
      color="error"
      variant="outlined"
      size="small"
      {...props}
    />
  )
}

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const FilterButton = (props) => {
  return (
    <Button
      startIcon={<FilterAltIcon />}
      color="inherit"
      size="small"
      {...props}
    />
  )
}
