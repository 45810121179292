import React from "react"
import {
  CardContent,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { SubCard } from "shared/ui/berry-jass"

/**
 * @template T
 * @param {{value: T | T[]; label: React.ReactNode; color: string; getChipLabel?: (T) => string | number; onDelete: Function}} props
 */
export const FilterChips = ({
  value,
  label,
  color,
  getChipLabel = (x) => x,
  onDelete
}) => {
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"))
  const val = value instanceof Array ? value : [value]

  return (
    <SubCard
      sx={{
        borderColor: "#DFDFDF",
        width: "fit-content"
      }}
      content={false}
    >
      <CardContent sx={{ py: "12px !important", p: 2 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item sx={{ mr: 1 }}>
            <Typography variant="h6">{label}</Typography>
          </Grid>
          {val.map((item, index) => {
            const handleDelete = onDelete ? () => onDelete(item) : null

            return (
              <Grid item key={index}>
                <Chip
                  sx={{ fontSize: "12px", fontWeight: "500" }}
                  size={matchDownMD ? "medium" : undefined}
                  label={getChipLabel(item)}
                  onDelete={handleDelete}
                  color={color}
                />
              </Grid>
            )
          })}
        </Grid>
      </CardContent>
    </SubCard>
  )
}
